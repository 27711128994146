<template>
  <svg width="15" height="20" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="#F93"
      stroke-width="1.5"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M14 12.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0zM7.5 14.5v-2.546"
      />
      <path
        d="M7.497 10.431a.75.75 0 1 0 .007 1.5.75.75 0 0 0-.007-1.5M3.77 3.848a3.791 3.791 0 0 1 7.46.941v2.39"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconLogout",
};
</script>

<style></style>
